<template>
    <div @click="clickClose" class="text-center image-modal-background ">
        <div class="d-flex align-items-center justify-content-center">
            <img @click="clickClose" class="exit-btn" src="https://kfmetaverse.blob.core.windows.net/static/assets/Icon_Exit.png" alt="..." />

            <img class="detail-image" :src="imageUrl" alt="..." />
        </div>
    </div>
</template>

<script>
export default {
    props: ["imageUrl"],
    methods: {
        clickClose() {
            this.$emit("closeFromChild");
        }
    }
}
</script>

<style scoped>
.image-modal-background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0px;
    top: 0px;
    background-color: rgb(0,0,0,0.8);
    z-index: 9999;
}
.image-modal-background > div {
    width: 100%;
    height: 100%;
}
.detail-image {
    max-height: 100%;
    max-width: 100%;
}
.exit-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    padding-top: calc(var(--mdb-gutter-x)*.5);
    padding-bottom: calc(var(--mdb-gutter-x)*.5);
    cursor: pointer;
    z-index: 9999;
}
</style>