<template>
    <div id="backGround">
        <SceneLoading v-if="readyToSceneTrigger"></SceneLoading>
        <canvas id="jejuCanvas" ref="jejuCanvas"></canvas>
        <ContentModal v-if="modalTrigger" :content="modalContent" @closeFromChild="closeModal" />
        <Docent v-if="docentTrigger" :content="docentScript"/>
        <MouseModal :modalData="mouseModalData" :modalTrigger="mouseModalTrigger" :mouseX="mouseModalMouseX" :mouseY="mouseModalMouseY" />
    </div>
</template>

<script>
import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import "@babylonjs/loaders";
import { onMounted, ref, onUnmounted } from "@vue/runtime-core";
import SceneManager from '@/sceneManager/sceneManager';
import axios from 'axios';
import ContentModal from '@/components/modal/ContentModal';
import Docent from '@/components/docent/Docent';
import SceneLoading from '../components/loading/SceneLoading.vue';
import MouseModal from '../components/tool/MouseModal.vue';

export default {
    components: {
        ContentModal,
        Docent,
        SceneLoading,
        MouseModal
    },
    setup() {
        let sceneManager;
        const jejuCanvas = ref(null);
        const readyToSceneTrigger = ref(true);
        const allData = ref(null);
        const modalContent = ref(null);
        const modalTrigger = ref(false);
        const docentTrigger = ref(false);
        const docentScript = ref(null);
        const moveState = ref(null);
        const isWPressed= ref(false);
        const isSPressed= ref(false);
        const isAPressed= ref(false);
        const isDPressed= ref(false);
        const isKeyRotate= ref(false);
        const isKeyOnRotate= ref(false);
        const isRotateUpdate= ref(false);

        const mouseModalTrigger = ref(false);
        const mouseModalData = ref(null);
        const mouseModalMouseX = ref(0);
        const mouseModalMouseY = ref(0);
        
        function searchParam(key) {
            return new URLSearchParams(location.search).get(key);
        }

        const avatarIndex = ref(searchParam('space'));

        onMounted( async () => {
            allData.value = await getAllData();
            
            sceneManager = SceneManager.getInstance();
            sceneManager.setEngine(new BABYLON.Engine(jejuCanvas.value));
            sceneManager.setScene(new BABYLON.Scene(sceneManager.engine));

            sceneManager.scene.createDefaultEnvironment({
                enableGroundMirror: false,
                createGround: false,
                createSkybox: false,
            });

            sceneManager.scene.environmentIntensity = 0.6;

            sceneManager.scene.collisionsEnabled = true;
            
            //createCamera
            createCamera(sceneManager.scene);

            const avatar = await createAvatar(sceneManager.scene);
            clickMove(sceneManager.scene);

            //createLight
            createLight(sceneManager.scene);

            //createBackGround
            createBackground(sceneManager.scene);

            createSkyBox(sceneManager.scene);

            // createSymbolLink(sceneManager.scene);

            sceneManager.scene.activeCamera.lockedTarget = avatar.pivotCamera;

            sceneManager.engine.runRenderLoop(()=>{
                sceneManager.scene.render();
                avatar.move();
            });

            sceneManager.scene.executeWhenReady(()=>{
                readyToSceneTrigger.value = false;
                goToSpot(avatar);
            });

            window.addEventListener("resize", () => {
                if (jejuCanvas.value) {
                    jejuCanvas.value.width = window.innerWidth;
                    jejuCanvas.value.height = window.innerHeight;
                }
            });
        });

        const goToSpot = (avatar) => {
            if (avatarIndex.value != null) {
                if (avatarIndex.value == 1) {
                    sceneManager.scene.activeCamera.spinTo("alpha", BABYLON.Tools.ToRadians(135), 50);
                    moveState.value.hit = true;
                    moveState.value.pickedVec = new BABYLON.Vector3(40, 0.5, -40);
                    let vec = moveState.value.pickedVec;
                    let dir = vec.subtract(avatar.position);
                    vec.y +=1;
                    moveState.value.targetVec = vec;
                    moveState.value.targetVecNorm = BABYLON.Vector3.Normalize(dir);
                    moveState.value.isMove = true;
                    moveState.value.endCallBack = ()=>{
                        isWPressed.value = false;
                        moveState.value.isMove = false;
                    };
                } else if (avatarIndex.value == 2) {
                    sceneManager.scene.activeCamera.spinTo("alpha", BABYLON.Tools.ToRadians(180), 50);
                    moveState.value.hit = true;
                    moveState.value.pickedVec = new BABYLON.Vector3(55, 0.5, 0);
                    let vec = moveState.value.pickedVec;
                    let dir = vec.subtract(avatar.position);
                    vec.y +=1;
                    moveState.value.targetVec = vec;
                    moveState.value.targetVecNorm = BABYLON.Vector3.Normalize(dir);
                    moveState.value.isMove = true;
                    moveState.value.endCallBack = ()=>{
                        isWPressed.value = false;
                        moveState.value.isMove = false;
                    };
                } else if (avatarIndex.value == 3) {
                    sceneManager.scene.activeCamera.spinTo("alpha", BABYLON.Tools.ToRadians(220), 50);
                    moveState.value.hit = true;
                    moveState.value.pickedVec = new BABYLON.Vector3(40, 0.5, 40);
                    let vec = moveState.value.pickedVec;
                    let dir = vec.subtract(avatar.position);
                    vec.y +=1;
                    moveState.value.targetVec = vec;
                    moveState.value.targetVecNorm = BABYLON.Vector3.Normalize(dir);
                    moveState.value.isMove = true;
                    moveState.value.endCallBack = ()=>{
                        isWPressed.value = false;
                        moveState.value.isMove = false;
                    };
                } else if (avatarIndex.value == 4) {
                    sceneManager.scene.activeCamera.spinTo("alpha", BABYLON.Tools.ToRadians(45), 50);
                    moveState.value.hit = true;
                    moveState.value.pickedVec = new BABYLON.Vector3(-39, 0.5, -39);
                    let vec = moveState.value.pickedVec;
                    let dir = vec.subtract(avatar.position);
                    vec.y +=1;
                    moveState.value.targetVec = vec;
                    moveState.value.targetVecNorm = BABYLON.Vector3.Normalize(dir);
                    moveState.value.isMove = true;
                    moveState.value.endCallBack = ()=>{
                        isWPressed.value = false;
                        moveState.value.isMove = false;
                    };
                } else if (avatarIndex.value == 5) {
                    sceneManager.scene.activeCamera.spinTo("alpha", BABYLON.Tools.ToRadians(0), 50);
                    moveState.value.hit = true;
                    moveState.value.pickedVec = new BABYLON.Vector3(-55, 0.5, 0);
                    let vec = moveState.value.pickedVec;
                    let dir = vec.subtract(avatar.position);
                    vec.y +=1;
                    moveState.value.targetVec = vec;
                    moveState.value.targetVecNorm = BABYLON.Vector3.Normalize(dir);
                    moveState.value.isMove = true;
                    moveState.value.endCallBack = ()=>{
                        isWPressed.value = false;
                        moveState.value.isMove = false;
                    };
                } else if (avatarIndex.value == 6) {
                    sceneManager.scene.activeCamera.spinTo("alpha", BABYLON.Tools.ToRadians(-45), 50);
                    moveState.value.hit = true;
                    moveState.value.pickedVec = new BABYLON.Vector3(-40, 0.5, 40);
                    let vec = moveState.value.pickedVec;
                    let dir = vec.subtract(avatar.position);
                    vec.y +=1;
                    moveState.value.targetVec = vec;
                    moveState.value.targetVecNorm = BABYLON.Vector3.Normalize(dir);
                    moveState.value.isMove = true;
                    moveState.value.endCallBack = ()=>{
                        isWPressed.value = false;
                        moveState.value.isMove = false;
                    };
                }
            }
        };

        const getAllData = async () => {
            const res = await axios.get(`${process.env.VUE_APP_API_HOST}scene`);
            if (res.status == 200) {
                return res.data.data;
            }
            return null
        };

        const createSkyBox = (scene) => {
            const SkyBoxMaterial = new BABYLON.StandardMaterial("skyBoxMaterial", scene);
            SkyBoxMaterial.backFaceCulling = false;
            SkyBoxMaterial.diffuseTexture = new BABYLON.Texture("https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/skyBox3.jpg", scene);
            SkyBoxMaterial.diffuseTexture.level = 1;
            SkyBoxMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
            SkyBoxMaterial.specularColor = new BABYLON.Color3(0,0,0);
            SkyBoxMaterial.freeze();
            const skyBox = BABYLON.MeshBuilder.CreateSphere("skyBox", {diameter:400}, scene);
            skyBox.rotation = new BABYLON.Vector3(BABYLON.Tools.ToRadians(180),0,0);
            skyBox.material = SkyBoxMaterial;
        };

        const createBackground = async (scene) => {
            const meshResult = await BABYLON.SceneLoader.ImportMeshAsync("", "https://lotte-static.s3.ap-northeast-2.amazonaws.com/asset/", `jeju2.glb`, scene);

            async function setMesh(newMeshes){
                const lobby = newMeshes[0];
                lobby.position = new BABYLON.Vector3(0,0,0);
                lobby.rotation = new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(360),0);
                const gl = new BABYLON.GlowLayer("glow", scene);
                let index = 0;
                for (let i=0; i<allData.value.length; i++) {
                    for (let k=0; k<allData.value[i].contents.length; k++) {
                        index += 1;
                        let mesh;
                        if (index < 10) {
                            mesh = scene.getMeshByName(`content_00${index}`);
                        } else if (10 < index <= 30) {
                            mesh = scene.getMeshByName(`content_0${index}`);
                        } 
                        if (mesh) {
                            modelContentUtils(scene, mesh, allData.value[i].contents[k]);
                        }
                    }
                }

                for (let j=0; j<newMeshes.length; j++) {
                    newMeshes[j].checkCollisions = true;
                    if (newMeshes[j].name.includes('glow')) {
                        newMeshes[j].material.emissiveColor = new BABYLON.Color3(1,1,1);
                        gl.addIncludedOnlyMesh(newMeshes[j]);
                    } else if (newMeshes[j].name.includes('col_')) {
                        newMeshes[j].isPickable = false;
                    } else {
                        gl.addIncludedOnlyMesh(newMeshes[j]);
                    }
                }
            }
            await setMesh(meshResult.meshes);
            
            const alphaMat = new BABYLON.StandardMaterial('alpha', scene);
            alphaMat.alpha = 0;

            const avatar = scene.getMeshByName('avatar');
            // avatar.material = alphaMat;

            const symbolBox = new BABYLON.MeshBuilder.CreateCylinder("cone", {diameter: 30, height: 15}, scene); //scene is 
            symbolBox.position = new BABYLON.Vector3(0,0,27);
            symbolBox.checkCollisions = true;
            symbolBox.isPickable = false;
            symbolBox.material = alphaMat;

            const lobbyDocent = new BABYLON.MeshBuilder.CreateBox('lobby', {width:30,height:0.5, depth:30}, scene);
            lobbyDocent.position = new BABYLON.Vector3(0,0,0);
            lobbyDocent.isPickable = false;
            lobbyDocent.material = alphaMat;

            const space1Docent = new BABYLON.MeshBuilder.CreateBox('space1', {width:22,height:0.5, depth:15}, scene);
            space1Docent.position = new BABYLON.Vector3(-39,0,-39);
            space1Docent.rotation = new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(135.1),0);
            space1Docent.isPickable = false;
            space1Docent.material = alphaMat;

            const space2Docent = new BABYLON.MeshBuilder.CreateBox('space2', {width:32,height:0.5, depth:17}, scene);
            space2Docent.position = new BABYLON.Vector3(-55,0,0);
            space2Docent.isPickable = false;
            space2Docent.material = alphaMat;

            const space3Docent = new BABYLON.MeshBuilder.CreateBox('space3', {width:24,height:0.5, depth:17}, scene);
            space3Docent.position = new BABYLON.Vector3(-40,0,40);
            space3Docent.rotation = new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(44.7),0);
            space3Docent.isPickable = false;
            space3Docent.material = alphaMat;

            const space4Docent = new BABYLON.MeshBuilder.CreateBox('space4', {width:30,height:0.5, depth:17}, scene);
            space4Docent.position = new BABYLON.Vector3(40,0,40);
            space4Docent.rotation = new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(135.1),0);
            space4Docent.isPickable = false;
            space4Docent.material = alphaMat;

            const space5Docent = new BABYLON.MeshBuilder.CreateBox('space5', {width:32,height:0.5, depth:17}, scene);
            space5Docent.position = new BABYLON.Vector3(55,0,0);
            space5Docent.isPickable = false;
            space5Docent.material = alphaMat;

            const space6Docent = new BABYLON.MeshBuilder.CreateBox('space6', {width:30,height:0.5, depth:17}, scene);
            space6Docent.position = new BABYLON.Vector3(40,0,-40);
            space6Docent.rotation = new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(42.5),0);
            space6Docent.isPickable = false;
            space6Docent.material = alphaMat;

            avatar.actionManager = new BABYLON.ActionManager(scene);
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter : { mesh : lobbyDocent}}, ()=> {
                for (let i=0; i<allData.value.length; i++) {
                    if (allData.value[i].sceneType == 'lobby') {
                        docentScript.value = {
                            "ko": allData.value[i].docent.script,
                            "en": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.eng.script : allData.value[i].docent.script,
                            "jp": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.jp.script : allData.value[i].docent.script,
                            "cn": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.cn.script : allData.value[i].docent.script
                        };
                        docentTrigger.value = !docentTrigger.value;
                    }
                }
            }));
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionExitTrigger, parameter : { mesh : lobbyDocent}}, ()=> {
                docentScript.value = {};
                docentTrigger.value = !docentTrigger.value;
            }));
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter : { mesh : space1Docent}}, ()=> {
                for (let i=0; i<allData.value.length; i++) {
                    if (allData.value[i].sceneType == 'space1') {
                        docentScript.value = {
                            "ko": allData.value[i].docent.script,
                            "en": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.eng.script : allData.value[i].docent.script,
                            "jp": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.jp.script : allData.value[i].docent.script,
                            "cn": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.cn.script : allData.value[i].docent.script
                        };
                        docentTrigger.value = !docentTrigger.value;
                    }
                }
            }));
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionExitTrigger, parameter : { mesh : space1Docent}}, ()=> {
                docentScript.value = {};
                docentTrigger.value = !docentTrigger.value;
            }));

            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter : { mesh : space2Docent}}, ()=> {
                for (let i=0; i<allData.value.length; i++) {
                    if (allData.value[i].sceneType == 'space2') {
                        docentScript.value = {
                            "ko": allData.value[i].docent.script,
                            "en": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.eng.script : allData.value[i].docent.script,
                            "jp": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.jp.script : allData.value[i].docent.script,
                            "cn": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.cn.script : allData.value[i].docent.script
                        };
                        docentTrigger.value = !docentTrigger.value;
                    }
                }
            }));
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionExitTrigger, parameter : { mesh : space2Docent}}, ()=> {
                docentScript.value = {};
                docentTrigger.value = !docentTrigger.value;
            }));

            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter : { mesh : space3Docent}}, ()=> {
                for (let i=0; i<allData.value.length; i++) {
                    if (allData.value[i].sceneType == 'space3') {
                        docentScript.value = {
                            "ko": allData.value[i].docent.script,
                            "en": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.eng.script : allData.value[i].docent.script,
                            "jp": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.jp.script : allData.value[i].docent.script,
                            "cn": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.cn.script : allData.value[i].docent.script
                        };
                        docentTrigger.value = !docentTrigger.value;
                    }
                }
            }));
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionExitTrigger, parameter : { mesh : space3Docent}}, ()=> {
                docentScript.value = {};
                docentTrigger.value = !docentTrigger.value;
            }));

            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter : { mesh : space4Docent}}, ()=> {
                for (let i=0; i<allData.value.length; i++) {
                    if (allData.value[i].sceneType == 'space4') {
                        docentScript.value = {
                            "ko": allData.value[i].docent.script,
                            "en": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.eng.script : allData.value[i].docent.script,
                            "jp": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.jp.script : allData.value[i].docent.script,
                            "cn": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.cn.script : allData.value[i].docent.script
                        };
                        docentTrigger.value = !docentTrigger.value;
                    }
                }
            }));
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionExitTrigger, parameter : { mesh : space4Docent}}, ()=> {
                docentScript.value = {};
                docentTrigger.value = !docentTrigger.value;
            }));

            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter : { mesh : space5Docent}}, ()=> {
                for (let i=0; i<allData.value.length; i++) {
                    if (allData.value[i].sceneType == 'space5') {
                        docentScript.value = {
                            "ko": allData.value[i].docent.script,
                            "en": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.eng.script : allData.value[i].docent.script,
                            "jp": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.jp.script : allData.value[i].docent.script,
                            "cn": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.cn.script : allData.value[i].docent.script
                        };
                        docentTrigger.value = !docentTrigger.value;
                    }
                }
            }));
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionExitTrigger, parameter : { mesh : space5Docent}}, ()=> {
                docentScript.value = {};
                docentTrigger.value = !docentTrigger.value;
            }));

            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter : { mesh : space6Docent}}, ()=> {
                for (let i=0; i<allData.value.length; i++) {
                    if (allData.value[i].sceneType == 'space6') {
                        docentScript.value = {
                            "ko": allData.value[i].docent.script,
                            "en": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.eng.script : allData.value[i].docent.script,
                            "jp": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.jp.script : allData.value[i].docent.script,
                            "cn": allData.value[i].docent.subLang ? allData.value[i].docent.subLang.cn.script : allData.value[i].docent.script
                        };
                        docentTrigger.value = !docentTrigger.value;
                    }
                }
            }));
            avatar.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger : BABYLON.ActionManager.OnIntersectionExitTrigger, parameter : { mesh : space6Docent}}, ()=> {
                docentScript.value = {};
                docentTrigger.value = !docentTrigger.value;
            }));
        };

        const modelContentUtils = (scene, mesh, data) => {
            if (data.thumbnailUrl == null) {
                return
            }
            const thumbnailTexture = new BABYLON.Texture(`${process.env.VUE_APP_API_HOST}${data.thumbnailUrl}`, scene);
            thumbnailTexture.onLoadObservable.add(loadedTExture => {
                const textureSize = loadedTExture.getSize();
                const size = {"width" : textureSize.width/80, "height" : textureSize.height/80};
                if (size.width > 17) {
                    size.width = size.width/1.7;
                    size.height = size.height/1.7;
                }

                const thumbnailMaterial = new BABYLON.StandardMaterial("", scene);
                thumbnailMaterial.diffuseTexture = thumbnailTexture;
                thumbnailMaterial.diffuseTexture.hasAlpha = true;
                thumbnailMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
                thumbnailMaterial.specularColor = new BABYLON.Color3(0,0,0);
                
                const box = new BABYLON.MeshBuilder.CreateBox("thumbBox", {width:size.width + 0.2, height:size.height + 0.2, depth:0.4}, scene);
                box.position = new BABYLON.Vector3(0,0,0.1);
                const boxMaterial = new BABYLON.StandardMaterial("", scene);
                if (mesh.name == 'content_017' || mesh.name == 'content_018' || mesh.name == 'content_019' || mesh.name == 'content_020' || mesh.name == 'content_021') {
                    boxMaterial.diffuseColor = new BABYLON.Color3(1,1,1);
                    boxMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
                } else {
                    boxMaterial.diffuseColor = new BABYLON.Color3(0,0,0);
                    boxMaterial.emissiveColor = new BABYLON.Color3(0,0,0);
                }
                boxMaterial.specularColor = new BABYLON.Color3(0,0,0);
                box.material = boxMaterial;
                const plane = new BABYLON.MeshBuilder.CreatePlane("thumbPlane", {width:size.width, height:size.height, updatable:true, sideOrientation:BABYLON.Mesh.DOUBLESIDE}, scene);
                plane.position = new BABYLON.Vector3(0,0,-0.2);
                box.setParent(plane);
                plane.position = new BABYLON.Vector3(mesh._absolutePosition.x, mesh._absolutePosition.y, mesh._absolutePosition.z);
                const targetMesh = scene.getMeshByName(`${mesh.name}_target`);
                if (targetMesh) {
                    plane.lookAt(targetMesh._absolutePosition);
                    plane.rotation.y -= BABYLON.Tools.ToRadians(180);
                    targetMesh.isVisible = false;
                }
                plane.material = thumbnailMaterial;
                
                mesh.isVisible = false;

                const modalData = data;
                if (modalData.dataType != 'youtube') {
                    modalData.dataUrl = process.env.VUE_APP_API_HOST + modalData.dataUrl;
                }

                plane.actionManager = new BABYLON.ActionManager(scene);
                if (data.dataType == "pdf") {
                    plane.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
                        const avatar = scene.getMeshByName('avatar');
                        const rotateLimit = avatar.position.subtract(plane.position);
                        const distanceBetweenAvatarPlane = rotateLimit.length() - avatar.getBoundingInfo().boundingSphere.radius - plane.getBoundingInfo().boundingSphere.radius;
                        if (distanceBetweenAvatarPlane < 72) { 
                            modalTrigger.value = true;
                            modalContent.value = modalData;
                        } else {
                            return
                        }
                    }));
                } else if (data.dataType == "image") {
                    plane.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
                        const avatar = scene.getMeshByName('avatar');
                        const rotateLimit = avatar.position.subtract(plane.position);
                        const distanceBetweenAvatarPlane = rotateLimit.length() - avatar.getBoundingInfo().boundingSphere.radius - plane.getBoundingInfo().boundingSphere.radius;
                        if (distanceBetweenAvatarPlane < 72) { 
                            modalTrigger.value = true;
                            modalContent.value = modalData;
                        } else {
                            return
                        }
                    }));
                } else if (data.dataType == "youtube") {
                    plane.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
                        const avatar = scene.getMeshByName('avatar');
                        const rotateLimit = avatar.position.subtract(plane.position);
                        const distanceBetweenAvatarPlane = rotateLimit.length() - avatar.getBoundingInfo().boundingSphere.radius - plane.getBoundingInfo().boundingSphere.radius;
                        if (distanceBetweenAvatarPlane < 72) { 
                            modalTrigger.value = true;
                            modalContent.value = modalData;
                        } else {
                            return
                        }
                    }));
                }

                const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;

                if (!isMobile) {
                    plane.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function(e){
                        const avatar = scene.getMeshByName('avatar');
                        const rotateLimit = avatar.position.subtract(plane.position);
                        const distanceBetweenAvatarPlane = rotateLimit.length() - avatar.getBoundingInfo().boundingSphere.radius - plane.getBoundingInfo().boundingSphere.radius;
                        if (distanceBetweenAvatarPlane < 72) {
                            mouseModalData.value = null;
                            mouseModalTrigger.value = true;
                            mouseModalData.value = modalData;
                            mouseModalMouseX.value = e.pointerX;
                            mouseModalMouseY.value = e.pointerY;
                        } else {
                            return
                        }
                    }));
    
                    plane.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function(e){
                        mouseModalTrigger.value = false;
                        mouseModalData.value = null;
                        mouseModalMouseX.value = e.pointerX;
                        mouseModalMouseY.value = e.pointerY;
                    }));
                } 
            });
        };

        const createLight = (scene) => {
            const light = new BABYLON.HemisphericLight("hemiLight", new BABYLON.Vector3(0, -1, 0), scene);
            light.intensity = 0.6;

            const light1 = new BABYLON.HemisphericLight("hemiLight", new BABYLON.Vector3(0, 1, 0), scene);
            light1.intensity = 0.3;

            // scene.debugLayer.show({ embedMode: true }).then(() => {
            //     scene.debugLayer.select(light);
            // });
        };

        class CMoveState {
            constructor(){
                this.hit  = false;
                this.date = Date.now();
                this.isMove=false;
                this.pickedVec = BABYLON.Vector3.Zero();
                this.targetVec= BABYLON.Vector3.Zero();
                this.targetVecNorm= BABYLON.Vector3.Zero();
                this.speed=0.5;
                this.endCallBack = ()=>{console.log("init endCallBack")};
            }
        }

        const createCamera = (scene) => {
            const camera = new BABYLON.ArcRotateCamera("arcCamera", Math.PI / 2, Math.PI/2, 20, new BABYLON.Vector3(0,0,0), scene);    
            camera.attachControl(jejuCanvas.value, true);
            // camera.checkCollisions = true;
            camera.ellipsoid = new BABYLON.Vector3(0.5,3,0.5);
            camera.lowerRadiusLimit = 3;
            camera.upperRadiusLimit = 3;
            
            camera.angularSensibilityX = 6000;
            camera.angularSensibilityY = 6000;
            camera.inputs.remove(camera.inputs.attached.keyboard);
            const ease = new BABYLON.CubicEase();
            ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
            BABYLON.ArcRotateCamera.prototype.spinTo = function (whichprop, targetval, speed) {
                BABYLON.Animation.CreateAndStartAnimation('at4', this, whichprop, speed, 60, this[whichprop], targetval, 0, ease);
            }
            camera.spinTo("alpha", BABYLON.Tools.ToRadians(-90), 60);

            const UserAgent = navigator.userAgent;
            if (UserAgent.match(/iPhone|iPod|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null) {
                camera.upperBetaLimit = 2.5;
                camera.lowerBetaLimit = 1.6;
            } 
        };

        const createAvatar = async (scene) => {
            const avatar = new BABYLON.MeshBuilder.CreateBox('avatar', { height: 2, depth: 0.5, width: 0.5 }, scene);
            avatar.position =  new BABYLON.Vector3(0,1,-26);
           
            avatar.checkCollisions = true;
            avatar.rotation = new BABYLON.Vector3(0,BABYLON.Tools.ToRadians(0),0);

            const pivot = new BABYLON.TransformNode(`camera`);
            pivot.position = new BABYLON.Vector3(0,5,-26);
            avatar.pivotCamera = pivot;
            pivot.setParent(avatar);

            moveState.value = new CMoveState();

            avatar.speed = new BABYLON.Vector3(0, 0, 0.5);
            avatar.frontVector = new BABYLON.Vector3(0, 1, 0);
            avatar.load = false;
            avatar.isUpdateMove = false;
            avatar.isPickable = false;
            avatar.isVisible = false;
            
            avatar.state = {
                x: avatar.position.x,
                y: 5,
                z: avatar.position.z,
                rX: avatar.rotation.x,
                rY: avatar.rotation.y,
                rZ: avatar.rotation.z,
            }

            avatar.move = function () {
                let notifyServer = false;
                let frontVector = BABYLON.Vector3.Zero();
                let speed = 0.5;
                if(moveState.value.isMove){
                    let vec = moveState.value.targetVec.subtract(avatar.position);
                    // vec.y=0;
                    vec = BABYLON.Vector3.Normalize(vec);
                    let dit = BABYLON.Vector3.Dot(vec,moveState.value.targetVecNorm);            
                    if(dit<=0)
                    {
                        moveState.value.endCallBack();
                        notifyServer = true;
                        avatar.position =new BABYLON.Vector3(moveState.value.targetVec.x,moveState.value.targetVec.y,moveState.value.targetVec.z);

                    }else {
                        speed = moveState.value.speed;
                        frontVector = moveState.value.targetVecNorm;
                        isWPressed.value = true;
                    }

                }else  if (isWPressed.value||isSPressed.value){
                    let camera = scene.activeCamera;
                    let front = BABYLON.Vector3.Normalize(avatar.position.subtract(camera.position));
                    let light = BABYLON.Vector3.Normalize(BABYLON.Vector3.Cross(BABYLON.Vector3.Up(), front ));
                    frontVector = BABYLON.Vector3.Normalize(BABYLON.Vector3.Cross(light, BABYLON.Vector3.Up()));            
                    if(isSPressed.value){
                        frontVector = new BABYLON.Vector3(-frontVector.x,-frontVector.y,-frontVector.z);
                    }
                }
                frontVector.y=0;

                updateAvatarRotate(avatar,frontVector);
                
                const frontVectorValue = frontVector.multiplyByFloats(speed, -0.1, speed);
                if (isWPressed.value) {
                    avatar.moveWithCollisions(new BABYLON.Vector3(frontVectorValue.x, -0.1, frontVectorValue.z));
                    notifyServer = true;
                }
                if (isSPressed.value) {
                    avatar.moveWithCollisions(new BABYLON.Vector3(frontVectorValue.x, -0.1, frontVectorValue.z));
                    notifyServer = true;
                }
                if (isAPressed.value) {
                    //카메라 회전 수정
                    scene.activeCamera.alpha = scene.activeCamera.alpha+0.02;
                }
                if (isDPressed.value) {                
                    //카메라 회전 수정
                    scene.activeCamera.alpha = scene.activeCamera.alpha-0.02;
                }
                if (notifyServer) {
                    avatar.state.x = avatar.position.x;
                    avatar.state.y = 5;
                    avatar.state.z = avatar.position.z;
                    avatar.state.rX = avatar.rotation.x;
                    avatar.state.rY = avatar.rotation.y;
                    avatar.state.rZ = avatar.rotation.z;
                }
            }
            return avatar;
        };

        const updateAvatarRotate = (avatar,frontVector) => {

            isKeyRotate.value = isWPressed.value || isSPressed.value;

            if(isKeyRotate.value && isKeyRotate.value!=isKeyOnRotate.value){
                isRotateUpdate.value = true;
            }
            isKeyOnRotate.value = isKeyRotate.value;
            if(isRotateUpdate.value)
            {
                let v = BABYLON.Vector3.Cross(avatar.frontVector, frontVector);

                if( v.y < 0 && v.y > -0.00000001)v.y=0;
                if( v.y > 0 && v.y < 0.00000001)v.y=0;

                if( v.y==0)
                {
                    let len = BABYLON.Vector3.Dot(avatar.frontVector, frontVector);
                    if(len<0)
                    {
                        v.y = 1;
                    }
                }
                let isEnd = false;
                if(v.y>0){
                    avatar.rotation.y += 0.2;
                let forward = new BABYLON.Vector3(Math.sin(avatar.rotation.y), 0, Math.cos(avatar.rotation.y));
                    v = BABYLON.Vector3.Cross(forward, frontVector);
                    if(v.y<=0){
                        isEnd = true;
                    }
                }
                else if(v.y<0)
                {
                    avatar.rotation.y -= 0.2;
                    let forward = new BABYLON.Vector3(Math.sin(avatar.rotation.y), 0, Math.cos(avatar.rotation.y));
                    v = BABYLON.Vector3.Cross(forward, frontVector);
                    if(v.y>=0){
                        isEnd = true;
                    }
                }
                if(isEnd)
                {
                    if(frontVector.x<0) {
                        avatar.rotation.y = -Math.acos(frontVector.z);
                    }else {
                        if(frontVector.z<0) avatar.rotation.y = Math.acos(frontVector.z);
                        else avatar.rotation.y = Math.asin(frontVector.x);
                    }
                    isKeyRotate.value=isKeyOnRotate.value=isRotateUpdate.value = false;
                }
                avatar.frontVector = new BABYLON.Vector3(Math.sin(avatar.rotation.y), Math.sin(avatar.rotation.y), Math.cos(avatar.rotation.y));
            }
        };

        const clickMove = (scene) => {
            const avatar = scene.getMeshByName('avatar');
            scene.onPointerDown= (e, pickResult) => {
                if (pickResult.pickedMesh && pickResult.pickedMesh.name.includes("floor")) {
                    moveState.value.hit = true;
                    moveState.value.pickedVec = pickResult.pickedPoint;
                    // moveState.value.pickedVec.y = 0.07;
                    moveState.value.date = Date.now();
                } 
            }
            scene.onPointerUp = (e) => {
                if (e.button === 0) { //left
                    if (moveState.value.hit) {  // === ground 제외
                        let millis = Date.now() - moveState.value.date;
                        if( millis >200 ) {
                            moveState.value.hit = false;
                            return;
                        }
                        let vec = moveState.value.pickedVec;
                        let dir = vec.subtract(avatar.position);
                        vec.y +=1;
                        moveState.value.targetVec = vec;
                        moveState.value.targetVecNorm = BABYLON.Vector3.Normalize(dir);
                        moveState.value.isMove = true;
                        moveState.value.endCallBack = ()=>{
                            isWPressed.value = false;
                            moveState.value.isMove = false;
                        };
                    }
                }
            };
        };

        // const createSymbolLink = (scene) => {

        //     const alphaMat = new BABYLON.StandardMaterial('alpha', scene);
        //     alphaMat.alpha = 0;

        //     const space1 = new BABYLON.MeshBuilder.CreateBox('basket', {width:5,height:4, depth:5}, scene);
        //     space1.position = new BABYLON.Vector3(-73.4,0.3,73.9);
        //     space1.checkCollisions = true;
        //     const space2 = new BABYLON.MeshBuilder.CreateBox('tree', {width:10,height:15, depth:10}, scene);
        //     space2.position = new BABYLON.Vector3(-97,7.6,1);
        //     space2.checkCollisions = true;
        //     const space3 = new BABYLON.MeshBuilder.CreateBox('boat1', {width:10,height:2, depth:10}, scene);
        //     space3.position = new BABYLON.Vector3(-62,1,-80);
        //     space3.checkCollisions = true;
        //     const space32 = new BABYLON.MeshBuilder.CreateBox('boat2', {width:10,height:2, depth:10}, scene);
        //     space32.position = new BABYLON.Vector3(-90,1,-74);
        //     space32.checkCollisions = true;
        //     const space4 = new BABYLON.MeshBuilder.CreateBox('well', {width:9,height:4, depth:9}, scene);
        //     space4.position = new BABYLON.Vector3(100,2,-1);
        //     space4.checkCollisions = true;
        //     const space5 = new BABYLON.MeshBuilder.CreateBox('water', {width:6,height:9, depth:6}, scene);
        //     space5.position = new BABYLON.Vector3(71,5,70);
        //     space5.checkCollisions = true;
        //     const space6 = new BABYLON.MeshBuilder.CreateBox('flower', {width:7.4,height:7.4, depth:7.5}, scene);
        //     space6.position = new BABYLON.Vector3(73,0,-73);
        //     space6.checkCollisions = true;

        //     space1.material = alphaMat;
        //     space2.material = alphaMat;
        //     space3.material = alphaMat;
        //     space32.material = alphaMat;
        //     space4.material = alphaMat;
        //     space5.material = alphaMat;
        //     space6.material = alphaMat;

        //     space1.actionManager = new BABYLON.ActionManager(scene);
        //     space1.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
        //         window.open('http://40.89.198.8/darangshi_cave/');
        //     }));

        //     space2.actionManager = new BABYLON.ActionManager(scene);
        //     space2.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
        //         window.open('http://40.89.198.8/nackseon-dong_43fortress/');
        //     }));

        //     space3.actionManager = new BABYLON.ActionManager(scene);
        //     space3.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
        //         window.open('http://40.89.198.8/neobeunsungi/');
        //     }));

        //     space32.actionManager = new BABYLON.ActionManager(scene);
        //     space32.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
        //         window.open('http://40.89.198.8/neobeunsungi/');
        //     }));

        //     space4.actionManager = new BABYLON.ActionManager(scene);
        //     space4.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
        //         window.open('http://40.89.198.8/lost_village_goneul-dong/');
        //     }));

        //     space5.actionManager = new BABYLON.ActionManager(scene);
        //     space5.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
        //         window.open('http://40.89.198.8/former_distillery_site/');
        //     }));

        //     space6.actionManager = new BABYLON.ActionManager(scene);
        //     space6.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function(){
        //         window.open('http://40.89.198.8/baekjoilsonjiji_collective_cemetery/');
        //     }));
            
        // };

        document.addEventListener("keydown", function (event) {
            if (event.key == 'w' || event.key == 'W' || event.key == 'ArrowUp') {
                isWPressed.value = true;
                if(moveState.value && moveState.value.isMove) { moveState.value.endCallBack(); }
            }
            if (event.key == 's' || event.key == 'S' || event.key == 'ArrowDown') {
                isSPressed.value = true;
                if(moveState.value.isMove) { moveState.value.endCallBack(); }
            }
            if (event.key == 'a' || event.key == 'A' || event.key == 'ArrowLeft') {
                isAPressed.value = true;
                if(moveState.value.isMove) { moveState.value.endCallBack(); }
            }
            if (event.key == 'd' || event.key == 'D' || event.key == 'ArrowRight') {
                isDPressed.value = true;
                if(moveState.value.isMove) { moveState.value.endCallBack(); }
            }
        });

        document.addEventListener("keyup", function (event) {
            if (event.key == 'w' || event.key == 'W' || event.key == 'ArrowUp') {
                isWPressed.value = false;
            }
            if (event.key == 's' || event.key == 'S' || event.key == 'ArrowDown') {
                isSPressed.value = false;
            }
            if (event.key == 'a' || event.key == 'A' || event.key == 'ArrowLeft') {
                isAPressed.value = false;
            }
            if (event.key == 'd' || event.key == 'D' || event.key == 'ArrowRight') {
                isDPressed.value = false;
            }
        });

        onUnmounted(()=>{
            sceneManager.closeScene();
            sceneManager.closeEngine();
        });

        return {
            jejuCanvas,
            readyToSceneTrigger,
            modalContent,
            modalTrigger,
            docentTrigger,
            docentScript,
            mouseModalTrigger,
            mouseModalData,
            mouseModalMouseX,
            mouseModalMouseY
        }
    },
    methods: {
        closeModal() {
            this.modalTrigger = !this.modalTrigger;
        }
    }
}


</script>


<style scoped>
#body{
    overflow: hidden;
}
#backGround{
    width: 100vw;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    display: block;
    overflow: hidden;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: black;
}
#jejuCanvas{
    width: 100vw;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    display: block;
    overflow: hidden;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: opacity 2s;
}

</style>