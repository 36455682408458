<template>
    <div class="text-center modal-background">
        <div class="row modal-box d-flex align-items-start justify-content-center">
            <div class="exit-box col-md-12">
                <img @click="clickClose" class="exit-btn" src="@/assets/modal-exit.png" alt="..." />
            </div>

            <div class="mt-3 content-box col-md-6">
               <img class="content-image" @click="toggleViewer" v-if="type==='image'" :src="dataUrl" />
               <VuePdf v-else-if="type==='pdf'" v-for="page in numOfPages" :key="page" :src="dataUrl" :page="page" />
               <iframe v-else-if="type==='youtube'" class="youtube-frame" :src="dataUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div class="mt-3 text-start content-inform-box col-md-6">
                <div>
                    <h3>{{currentLang==='ko' ? data.dataTitle : data.subLang.eng.title}}</h3>

                    <div class="data-desc">{{currentLang==='ko' ? data.dataDescription : data.subLang.eng.description}}</div>
                    <br />
                    <div v-if="data.metaData">
                        <a :href="data.metaData.link" target="_blank">{{data.metaData.linkName}}</a>
                    </div>
                    <div v-if="type==='pdf'">
                        <a :href="dataUrl" :download="data.dataTitle" target="_blank">Download PDF</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ImageViewerModalVue v-if="type==='image' && isOpenImageViewer" :imageUrl="dataUrl" @closeFromChild="toggleViewer" />
</template>

<script>
import { onMounted, ref, toRefs } from 'vue';
import { VuePdf, createLoadingTask } from 'vue3-pdfjs/esm';
import { useStore } from 'vuex'; 
// import { useRoute } from 'vue-router';
import { useGtag } from "vue-gtag-next";
import ImageViewerModalVue from './ImageViewerModal.vue';
import axios from "axios";
 
export default {
    props: ["content"],
    components: {
        VuePdf,
        ImageViewerModalVue
    },
    setup(props) {
        const { content } = toRefs(props);
        const dataUrl = ref(content.value.dataUrl);
        const numOfPages = ref(0);
        const store = useStore();
        // const route = useRoute();
        const { event } = useGtag();

        // const currentPath = route.path.split("/")[2]
        const currentLang = store.getters.getUserLang;

        const isOpenImageViewer = ref(false);

        onMounted(async() => {
            const loadingTask = createLoadingTask(dataUrl.value);
            loadingTask.promise.then((pdf) => {
                numOfPages.value = pdf.numPages
            });
            
            const url = `${process.env.VUE_APP_API_HOST}scene/${content.value.sceneId}/space-name`;
            const result = await axios.get(url);

            event(`click_${result.data.data.sceneType}_${content.value.number+1}`, {
                'event_category' : 'click',
                'event_label' : 'click'
            });
        });

        return {
            dataUrl,
            numOfPages,
            type: content.value.dataType,
            data: content.value,
            currentLang,
            isOpenImageViewer
        }
    },
    methods: {
        clickClose() {
            this.$emit("closeFromChild");
        },
        toggleViewer() {
            this.isOpenImageViewer = !this.isOpenImageViewer;
        }
    },
}
</script>

<style scoped>
.modal-background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0px;
    top: 0px;
    background-color: rgb(0,0,0,0.5);
    z-index: 9999;
}
.modal-box {
    position: absolute;
    width: 60%;
    min-height: 600px;
    max-height: 800px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.5rem;
    border-radius: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1001;
}
.exit-box {
    position: sticky;
    top: 0px;
}
.exit-btn {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 30px;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    z-index: 3;
}
.content-box {
    /* width: 100%; */
}
.content-box > img {
    width: 100%;
}
.youtube-frame {
    width: 100%;
    height: 100%;
    min-height: 500px;
}
.content-inform-box {
    position: sticky;
    top: 0px;
    width: 47% !important;
}
.content-image {
    cursor: pointer;
}
.data-desc {
    white-space: pre-wrap;
}
@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {
    .modal-box {
        height: 100%;
        min-height: 0px;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .modal-box {
        height: 100%;
        min-height: 0px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .content-inform-box {
        width: 100% !important;
    }
    .modal-box {
        height: 100%;
        min-height: 0px;
    }
}

@media (max-width: 481px) {
    .modal-box {
        width: 100%;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-47%, -50%);
    }
    .youtube-frame {
        min-height: 250px;
    }
    .content-inform-box {
        width: 100% !important;
    }
}
</style>