<template>
    <div id="loadingBox">
        <img src="@/assets/scene-loading.gif" alt="..." />
    </div>
</template>

<style scoped>
#loadingBox {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    z-index: 99999;
    background-color: black;
}
#loadingBox img {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}
</style>