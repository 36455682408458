<template>
    <div v-if="trigger" id="mouse" class="mouseModal" :style="{left:x + 'px', top:y + 'px'}">
        <div>{{ titleData }}</div>
    </div>
</template>

<script>
import { toRefs, ref } from 'vue';
import { useStore } from 'vuex';

export default {
    props: ["modalData", "modalTrigger", 'mouseX', 'mouseY'],
    setup(props) {
        const {modalData, modalTrigger, mouseX, mouseY} = toRefs(props);
        const store = useStore();
        const titleData = ref(null);


        return {
            data: modalData,
            trigger: modalTrigger,
            x: mouseX,
            y: mouseY,
            store,
            titleData,
        }
    },
    watch: {
        modalTrigger : function() {
            const currentLang = this.store.getters.getUserLang;

            if (this.modalTrigger == false) {
                return
            }
            if (currentLang == 'ko') {
                this.titleData = this.modalData.dataTitle;
            } else if (currentLang == 'cn') {
                this.titleData = this.modalData.subLang.cn.title;
            } else if (currentLang == 'en') {
                this.titleData = this.modalData.subLang.eng.title;
            } else if (currentLang == 'jp') {
                this.titleData = this.modalData.subLang.jp.title;
            }
        }
    }
}
</script>

<style scoped>
.mouseModal {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    border-radius: 30px;
    background-color: black;
    opacity: 0.5;
    color: white;
    transform: translate(-50%, -150%);
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: inline-block;
    font-size: smaller;
}

</style>