<template>
    <Transition>
        <video v-if="isOpenVideo" autoplay loop muted playsinline>
            <source :src="isMobile ? require('@/assets/docent.mp4') : require('@/assets/docent.webm')" :type="isMobile ? 'video/mp4' : 'video/webm'" />
        </video>
    </Transition>

    <img v-if="buttonTrigger" class="modalBtn" @click="startChat" src="@/assets/docent-start.png" alt="..." />

    <div v-if="startChatTrigger" class="modalContent">
        <p class="docent-text">{{contents[page]}}</p>
        <div v-if="totalPage > 1 && (page+1) < totalPage" class="next-page">
            <img @click="nextPage" src="@/assets/docent-next.png" alt=".." />
        </div>
    </div>

    <img v-if="startChatTrigger" class="closeModal" @click="closeChat" src="@/assets/modal-exit.png" alt="..." />
    <div v-if="startChatTrigger" id="npcModalBackground"></div>

</template>

<script>
import { onMounted, ref, toRefs } from 'vue';
import { useStore } from 'vuex';

export default {
    props: ["content"],
    setup(props) {
        const { content } = toRefs(props);
        const npcContent = ref(content.value)
        const buttonTrigger = ref(true);
        const startChatTrigger = ref(false);
        const isOpenVideo = ref(false);
        const store = useStore();
        const isMobile = ref(false);

        const contents = ref(content.value[store.getters.getUserLang].split("\n"));
        const totalPage = ref(contents.value.length);
        const page = ref(0);

        if (navigator.userAgent.match(/iPhone|iPod|iPad/) != null) {
            isMobile.value = true;
        } 

        onMounted(() => {

            document.body.addEventListener("click", function() {
                if(startChatTrigger.value && (page.value+1) < totalPage.value) {
                    // page.value += 1;
                }
            }, true);
        })

        return {
            npcContent,
            buttonTrigger,
            startChatTrigger,
            contents,
            totalPage,
            page,
            isOpenVideo,
            isMobile,
        }
    },
    methods: {
        startChat() {
            this.startChatTrigger = true;
            this.buttonTrigger = false;
            this.isOpenVideo = true;

            this.$emit("npcCameraStartEvent");
        },
        closeChat() {
            this.startChatTrigger = false;
            this.buttonTrigger = true;
            this.isOpenVideo = false;
            this.page = 0;

            this.$emit("npcCameraCloseEvent");
        },
        nextPage() {
            this.page += 1;
        }
    },
    computed: {
        setLang() {
            return this.$store.state.user.lang;
        }
    }
}
</script>

<style scoped>
#npcModalBackground {
    position: absolute;
    width: 20%;
    height: 10%;
    z-index: 9999;
    right: 0%;
    top: 0%;
}
.npm-modal {
    width: 100%;
    height: 100%;
}
.modalBtn {
    position: absolute;
    width: 80px;
    /* height: 80px; */
    left: 50%;
    /* top: 85%; */
    bottom: 5%;
    z-index: 9999;
    transform: translate(-50%);
    cursor: pointer;
    border: none;
    background-size: contain;
}
.modalContent {
    position: absolute;
    width: 50%;
    left: 50%;
    bottom: 15%;
    z-index: 9999;
    transform: translate(-50%);
    padding: 1.5rem;
    background-color: white;
    border-radius: 15px;
}
.closeModal {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 50%;
    bottom: 5%;
    z-index: 9999;
    transform: translate(-50%);
    cursor: pointer;
    border: none;
    background-size: contain;
}
.docent-text {
    margin-bottom: 0px !important;
}
.next-page {
    position: absolute;
    bottom: 0px;
    right: 1%;
    cursor: pointer;
}
video {
    width: 70%;
    position: absolute;
    bottom: 0px;
    left: -50%;
    transform: translateX(40%);
    z-index: 1000;
}

@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {
    video {
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: -50%;
        transform: translateX(50%);
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .closeModal {
        width: 40px;
        height: 40px;
        bottom: 2%;
    }
    video {
        width: 100%;
        position: absolute;
        bottom: -40px;
        left: -50%;
        transform: translateX(50%);
    }
    .modalContent {
        bottom: 15%;
    }
    .docent-text {
        font-size: smaller;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .modalContent {
        width: 70%;
        bottom: 10%;
    }
    .closeModal {
        width: 40px;
        height: 40px;
        bottom: 2%;
    }
    video {
        width: 100%;
        position: absolute;
        bottom: 0px;
        transform: translateX(50%);
    }
    .docent-text {
        font-size: smaller;
    }
}

@media (max-width: 481px) {
    .modalContent {
        width: 90%;
    }
    .docent-text {
        font-size: small;
    }
    .closeModal {
        width: 50px;
        height: 50px;
    }
    video {
        width: 320%;
        position: absolute;
        bottom: 0px;
        left: -237%;
    }
    .docent-text {
        font-size: smaller;
    }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>